.select-gallery-for-photo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    padding: 5px;
}
.select-gallery-for-photo:hover {
    background-color: rgb(233, 233, 233);
    transition: .7s;
}
.add-to-gallery-header {
    padding: 18px 0px;
    font-size: 21px;
    text-align: center;
    width: 100%;

}
.your-galleries-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 450px;
    width: 100%;
}
.select-gallery-list {
    padding: 0px;
    width: 100%;
}
.gallery-modal-photo-size {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}
.select-gallery-modal-title {
    font-size: 18px;
    font-weight: 400;
    margin-left: 15px;
}
.add-to-gallery-scroll {
    overflow-y: auto;
    width: 90%;
    max-height: 400px;
    border-top: 1px solid lightgray;
}
.placehoder-modal {
    border: 1px solid lightgray
}
.from-all-to-gallery-form{
    text-decoration: none;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
}
.create-gallery-modal {
    z-index: 2;
    background-color: white;
    border-radius: 28px;
    border: 2px solid #0870D1;
    min-width: 400px;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
    color: #0870D1;
    cursor: pointer;

}
.create-gallery-modal:hover {
background-color: rgb(228, 228, 228);
transition: .7s;

}
.gallery-modal-button-submit {
    width: 400px;
    padding-bottom: 25px;

}
.fa-x {
    font-size: 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    font-weight: 100;
}
.fa-plus {
    font-size: 22px;
    position: absolute;
    top: 104px;
    left: 50px;
    color: #0870D1;
    cursor: pointer;
}
