.single-gallery-cover {
    width: 100%;
    max-height: 370px;

}
.gallery-single-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edit-gallery-buttons {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
    margin-right: 40px;

}
.single-gallery-title {
    font-size: 21px;
    line-height: 24px;
}
.single-gallery-prof-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.single-gallery-photo-container {
    background-color: rgb(247, 248, 250);
    column-count: 3;
    gap: 8px;
    margin: 0px;
    padding: 60px 5px;
    width: 100%;
}
.image-size {
    image-resolution: from-image 300dpi;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 2px 0px;
}
.edit-gallery-button {
    background-color: transparent;
    border: none;
    cursor: pointer;

}
.gallery-placeholder{
    background-color: rgb(40, 40, 40);
    width: 100%;
    height: 370px;
}
.add-photos-gallery-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 700px;
    height: 700px;
    justify-content: center;
}
.fa-arrow-left-long {
    color: black;
}
.add-to-gallery-modal {
    padding: 0px;
    margin-right: 8px;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
.image-overlay-3 {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
    width: calc(100% - 25px);
    height: 90px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 10px 20px 0px 5px;
    text-align: center;
    z-index: 1;
    margin-top: 1.5px;
}
.photo-card:hover .image-overlay-3 , .photo-card:hover .image-overlay-4{
    opacity: 1;
    transition: 1.2s;
}
.image-overlay-4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
    width: calc(100% - 25px);
    height: 50px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 0px 20px 0px 5px;
    text-align: center;
    z-index: 1;
    margin-bottom: 5px;
}
.delete-from-gallery-button {
    height: 35px;
    width: 35px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 50%;
}
.fa-trash-can {
    font-size: 22px;
}
.delete-from-gallery-button:hover {
    transition: .7s;
    background-color: rgba(229, 229, 229, 0.342);
}
.add-to-gallery-button {
    background-color: white;
    color: rgb(8, 112, 209);
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: 2px solid rgb(8, 112, 209);
    width: 270px;
    cursor: pointer;
    text-align: center;
}
.add-gallery-photo-header {
    margin: 10px 0px 0px 0px;
    font-size: 20px;
}
