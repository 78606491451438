.photos-mapped-modal{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

}
.my-modal-photo {
    width: 200px;
    height: 200px;
    image-resolution: from-image 300dpi;
    object-fit: cover;
}
.my-modal-photo-container {
    width: 90%;
}
.photos-modal-container {
    min-width: 700px;
    max-height: 500px; 
    overflow-y: auto;
    padding: 16px;
    text-align: center;
    font-size: 14px;
}
.modal-photo-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.my-modal-photo-button {
    background-color: transparent;
    border: 2px solid transparent;
    padding: 2px 2px .5px 2px;
}
.border-click-on {
    border: 2px solid blue;
}
.add-photos-gallery-button {
    background-color: rgb(8, 112, 209);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 150px;
    cursor: pointer;
    text-align: center;
    margin: 15px;
}
.select-photos-modal-title {
    font-size: 20px;
}
