.gallery-card {
    width: 100%;
    height: 290px;
    cursor: pointer;
    box-shadow: 1px 2px 5px lightgray;
}

.all-galleries {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    padding: 5px 64px 20px 64px;
}

.all-gallery-image {
    width: 100%;
    height: 70%;
    object-fit: cover;
    margin: 0px;
}

.all-gallery-header {
    padding: 0px 64px;
}

.all-gallery-title {
    font-size: 36px;
    margin: 25px 0px 8px 0px;
    color: #222222;
}

.all-gallery-subheader {
    font-weight: normal;
    color: rgb(109, 115, 120);
    line-height: 20px;
    font-size: 16px;
    padding: 0px 0px 12px;
    margin: 0px;
}

.all-gallery-prof {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 7px;
}

.all-gallery-curated {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 16px;
}

.all-gallery-owner {

    margin: 0px;
    font-size: 16px;
}

.all-gallery-lower {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.all-gallery-to-profile {
    text-decoration: none;
    color: #0870D1;
    cursor: pointer;
    font-size: 16px;
}

.to-profile-button {
    background-color: transparent;
    border: none;
}

.all-galleries-background {
    background-color: rgb(247, 248, 250);
    padding-top: 3px;
    margin: 10px 0px;
}

@media screen and (max-width: 1200px) {
    .all-galleries {
        grid-template-columns: repeat(3, 1fr);
    }

}
@media screen and (min-width: 1600px) {
    .all-galleries {
        grid-template-columns: repeat(5, 1fr);
    }
}
@media screen and (max-width: 800px) {
    .all-galleries {
        grid-template-columns: repeat(2, 1fr);
    }

}
