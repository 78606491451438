.follower-modal-container{
    width: 300px;
}
.follower-modal-header{
    text-align: center;
    font-size: 20px;
}
.follower-modal-name-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 10px;
}
