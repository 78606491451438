.post-photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    max-height: 350px;
    border: 2px solid #0870d1;

}
.post-photo {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: cover;
    image-resolution: from-image 300dpi;

}

.post-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0px;
    margin: 0px 64px;

}
.upload-banner {
    margin: 0px 64px;
}
.post-whole-page-container {
    background-color: #f6f6f6dd;
}
