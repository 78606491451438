.main-footer-container {
    /* border: 3px green solid; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(34,34,34,.15);
    color: #222;
    font-size: 14px;
    line-height: 1.4;
    padding: 45px 100px;
}

.tech-and-about-container {
    /* border: pink 3px solid; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    margin-bottom: 30px;
}

.first-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.second-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.third-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.fourth-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.first-column :hover {
    text-decoration: underline;
}

.second-column :hover {
    text-decoration: underline;
}

.third-column :hover {
    text-decoration: underline;
}
.fourth-column :hover {
    text-decoration: underline;
}
