.photo-size {
    image-resolution: from-image 300dpi;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 20px 0px;
}
.mapped-photo-container {
    padding: 0px 64px;
}
.selected-subheader {
    color: #0870d1;
    text-decoration: none;
    padding-bottom: 5px;
    border-bottom: 2px solid #0870d1;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.not-selected-subheader {
    color: rgb(132, 132, 132);
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}
.selected-subheader:hover {
    color: #2986f7;
    border-bottom: 2px solid #2986f7;
}
.not-selected-subheader:hover {
    border-bottom: 2px solid #2986f7;
    padding-bottom: 5px;
    color: #2986f7;

}
.subheader-home {
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 100%;
    position: sticky;
    top: 63px;
    z-index: 1;
    height: 27px;
}
.all-photos {
    column-count: 3;
    gap: 50px;
    margin: 0px;
    padding: 0px;
}
.photo-card {
    max-width: 600px;
    cursor: pointer;
    position: relative;
    width: 100%;
}

.see-photos {
    font-weight: normal;
    color: rgb(109, 115, 120);
    line-height: 20px;
    font-size: 16px;
    padding: 0px 0px 12px;
    margin: 0px;
}
.photos-title {
    font-size: 36px;
    color: rgb(34, 34, 34);
    margin: 25px 0px 8px 0px;
}
.image-overlay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
    width: calc(100% - 25px);
    height: 50px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 0px 20px 0px 5px;
    text-align: center;
    z-index: 0;
    margin-bottom: 24px;
}
.image-overlay-2 {
    position: absolute;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
    width: calc(100% - 30px);
    height: 90px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 10px 20px 0px 10px;
    text-align: center;
    z-index: 0;
    margin-top: 20px;
}
.photo-card:hover .image-overlay , .photo-card:hover .image-overlay-2{
    opacity: 1;
    transition: 1.2s;
}

.overlay-2-text {
    display: flex;
    align-items: center;
    font-weight: lighter;
    align-self: left;
    margin: 0px;
    font-size: 16px;
}

.overlay-3-text {
    display: flex;
    font-weight: lighter;
    align-self: left;
    margin: 0px;
    font-size: 16px;
}
.add-gallery-modal {
    position: relative;
    bottom: 0px;
    display: flex;
    color: white;
    width: 22px;
    height: 22px;
    border: 2px solid white;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    padding: 0px 1px 2px 1px;
}
.gallery-modal-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: transparent;
    border: none;
    border-radius: 50%;
}
.overlay-bottom-text {
    position: absolute;
    bottom: 10px;
}
.overlay-right{
    position: absolute;
    right: 15px;
    bottom: 10px;
}
.gallery-modal-button:hover {

    background-color: rgba(229, 229, 229, 0.342);
    transition: .5s;
}
.all-photo-like-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 26px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.all-photo-like-button:hover, .all-photo-notlike-button:hover {

    background-color: rgba(229, 229, 229, 0.342);
    transition: .5s;
}
.all-photo-notlike-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: white;
    font-size: 26px;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.overlay-right-buttons{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1200px) {
    .all-photos {
        column-count: 2;
    }

}
@media screen and (min-width: 1600px) {
    .all-photos {
        column-count: 4;
    }
}
@media screen and (max-width: 800px) {
    .all-photos {
        column-count: 1;
    }
}
