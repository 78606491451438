textarea {
    resize: none;
    overflow-y: auto;
}
.delete-photo-button {
    display: inline-flex;
    border: 2px solid rgb(219, 56, 56);
    border-radius: 28px;
    background-color: white;
    color: rgb(219, 56, 56);
    margin: 15px 0px 0px 0px;
    cursor: pointer;
    font-size: 16px;
    width: fit-content;
    max-height: 48px;
    padding: 12px 22px;
    font-weight: bold;
}
.photo-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

}
.create-product-submit-button {
    background-color: #0870d1;
    color: white;
    border: none;
    border-radius: 28px;
    min-width: 140px;
    height: 48px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    margin: 15px 0px;
}
.create-product-submit-button:hover{
    background-color: #2986f7;
    transition: .7s;
}
.post-form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 20px;
    height: 750px;
    width: 360px;
    overflow-y: auto;
    border: 1px solid #e4e5e8;
    background-color: #fff;
}
.edit-form-title{
    font-size: 16px;
    font-weight: bold;
    position: sticky;
    top: 0px;
    background-color: #fff;
    width: 100%;
    height: 100px;
    text-align: center;
}
.form-is-required{
    align-self: flex-end;
    font-size: 14px;
    color: #6d7378;
}
.edit-input {
    margin: 0px;
    font-size: 14px;
    color: rgb(109, 115, 120);
    margin-bottom: 4px;
}
.select-field{
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px solid rgb(215, 216, 219);
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.5;
}
.input-box {
    width: 90%;
    height: 20px;
    border-radius: 4px;
    border: 1px solid rgb(215, 216, 219);
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.5;
}
.textarea-box{
    width: 90%;
    height: 84px;
    border-radius: 4px;
    border: 1px solid rgb(215, 216, 219);
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.5;
}
.input-box::placeholder, .textarea-box::placeholder, .select-field   {
    color: rgb(109, 115, 120);
}
.form-error {
    width: 100%;
    color: rgb(219, 56, 56);
    min-height: 24px;
    font-size: 14px;
}
.photo-delete-buttons-edit-page{
    display: flex;
    flex-direction: row;
}
