.edit-gallery-submit-button {
    background-color: rgb(8, 112, 209);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 165px;
    cursor: pointer;
}
.edit-gallery-submit-button:hover {
    background-color: #2986f7;
    transition: .7s;
}
.delete-gallery-button {
    color:  rgb(219, 56, 56);
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: bold;
    width: 170px;
    cursor: pointer;
}
.edit-gallery-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}
