.login-modal-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 400px;
}
.log-in-form {
    display: flex;
    flex-direction: column;
    align-items: center;

}
.login-header {
    display: flex;
    flex-direction: column;
    color: rgb(109, 115, 120);
    font-size: 14px;
}
.login-title{
    text-align: center;
    font-weight: normal;
    font-size: 24px;
}
.login-button {
    background-color: rgb(8, 112, 209);
    color: white;
    width: 100%;
    border: none;
    border-radius: 28px;
    padding: 12px 22px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.login-form-field {
    border: 1px solid rgb(215, 216, 219);
    height: 16px;
    padding: 12px 16px;
    font-size: 16px;
    color: rgb(34, 34, 34);
    border-radius: 4px;
    width: initial;
}
.login-modal-container {
    padding: 24px;
    border: 1px solid rgb(238, 239, 242);
}
.form-error {
    list-style: none;
}
.demo-login {
    background-color: white;
    border: 2px solid rgb(8, 112, 209);
    color: rgb(8, 112, 209);
    width: 100%;
    border-radius: 28px;
    padding: 12px 22px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
}
.login-button:hover {
    background-color: #2986f7;
    transition: .7s;
}
.demo-login:hover {
    background-color: rgb(237, 237, 237);
    transition: .7s;
}
