@import url(https://fonts.googleapis.com/css2?family=Noto+Sans);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
}

.photo-likes-modal-container{
    width: 300px;
}
.photo-like-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 10px;
}
.photo-like-modal-header{
    text-align: center;
    font-size: 20px;
}
.photo-like-container-name{
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.create-gallery-modal-container{
    min-width: 400px;
    width: 400px;
    padding: 0px 24px;
}
.create-new-gallery-header {
    font-size: 16px;
    color: rgb(34, 34, 34);
}

.create-gallery-modal-title-input{
    width: 89%;
    height: 40px;
    border: 1px solid rgb(215, 216, 219);
    border-radius: 4px;

}
.create-gallery-modal-label{
    color: rgb(129, 129, 129);
    font-size: 14px;
    margin: 5px 0px;
}
.create-gallery-modal-title-input{
    padding: 0px 24px;
}
.gallery-checkbox{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

}
.input-box-gallery {
    margin-right: 10px;
    height: 25px;
    width: 25px;
}
.create-gallery-modal-cancel-create {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;

}
.create-gallery-modal-submit-button{
    background-color: rgb(8, 112, 209);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 80%;
    margin-left: 15px;
    cursor: pointer;
}
.create-gallery-cancel-button{
    color: #0870D1;
    background-color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.select-gallery-for-photo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    padding: 5px;
}
.select-gallery-for-photo:hover {
    background-color: rgb(233, 233, 233);
    transition: .7s;
}
.add-to-gallery-header {
    padding: 18px 0px;
    font-size: 21px;
    text-align: center;
    width: 100%;

}
.your-galleries-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 450px;
    width: 100%;
}
.select-gallery-list {
    padding: 0px;
    width: 100%;
}
.gallery-modal-photo-size {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}
.select-gallery-modal-title {
    font-size: 18px;
    font-weight: 400;
    margin-left: 15px;
}
.add-to-gallery-scroll {
    overflow-y: auto;
    width: 90%;
    max-height: 400px;
    border-top: 1px solid lightgray;
}
.placehoder-modal {
    border: 1px solid lightgray
}
.from-all-to-gallery-form{
    text-decoration: none;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
}
.create-gallery-modal {
    z-index: 2;
    background-color: white;
    border-radius: 28px;
    border: 2px solid #0870D1;
    min-width: 400px;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
    color: #0870D1;
    cursor: pointer;

}
.create-gallery-modal:hover {
background-color: rgb(228, 228, 228);
transition: .7s;

}
.gallery-modal-button-submit {
    width: 400px;
    padding-bottom: 25px;

}
.fa-x {
    font-size: 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    font-weight: 100;
}
.fa-plus {
    font-size: 22px;
    position: absolute;
    top: 104px;
    left: 50px;
    color: #0870D1;
    cursor: pointer;
}

.single-photo-container {
    display: flex;
    flex-direction: row;
    padding: 24px;
    margin: 0px;
    align-items: center;
    justify-content: center;
    min-height: 650px;
}

.single-photo-nav {
    width: 33%;
    cursor: pointer;
}

.nav-placeholder {
    width: 33%;
}

.photo-background {
    background-color: rgb(40, 40, 40);
}

.page-background {
    background-color: rgb(245, 245, 245);
}

.single-photo-size {
    image-resolution: from-image 300dpi;
    width: 33%;
}

.single-photo {
    width: 100%;

}

.fa-chevron-left,
.fa-chevron-right {
    color: white;
    font-size: 24px;
    font-weight: 100px;
    width: 45px;
    height: 45px;
}

.fa-chevron-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
    text-align: center;
    border-radius: 50%;
}

.fa-chevron-left {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50px;
    text-align: center;
    border-radius: 50%;
}

.fa-chevron-left:hover,
.fa-chevron-right:hover {
    background-color: rgba(229, 229, 229, 0.342);
    transition: .7s;

}

.likes-chevron {
    color: black;
    position: relative;
    font-size: 12px;
    right: 8px;
    top: 2px;
}

.likes-chevron:hover {
    background-color: transparent;
}

.single-photo-likes-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.single-right-profile {
    display: flex;
    flex-direction: column;
}

.single-taken-updated {
    display: flex;
    flex-direction: row;
    font-size: 14px;
}

.single-lower-container {
    padding: 24px 16px 16px;
    display: flex;
    flex-direction: row;
}

.single-photo-about-container {
    background-color: white;
    width: 100%;
    padding: 24px;
}

.fa-pen-to-square {
    font-size: 22px;

}

.edit-photo-button {
    background-color: white;
    width: 35px;
    height: 35px;
    border: none;
    padding: 0px;
    border-radius: 100px;
    margin-right: 10px;
    cursor: pointer;
}

.edit-photo-button:hover {
    background-color: rgb(231, 231, 231);
    transition: .7s;
}

.single-photo-title {
    color: #222222;
    margin: 10px 0px;
}

.single-taken {
    font-weight: bold;
    padding-right: 5px;
}
.single-taken-data{
    margin-right: 10px;
}
.single-photo-likes {
    margin-top: 30px;
}

.single-category-data {
    padding: 1px 0px 0px 0px;
}

.single-camera-info,
.single-lense-info,
.single-category-info,
.single-location {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.single-photo-description {
    margin-top: 10px;
}

.single-camera-info {
    margin-top: 20px;
}

.lense-photo {
    height: 30px;
    margin: 0px 8px 0px 0px;
}

.single-category {
    font-weight: bold;
    font-size: 16px;
    margin: 0px 5px 0px 0px;
}

.single-location-taken-uploaded {
    display: flex;
    flex-direction: row;
}

.fa-location-dot {
    color: white;
    text-shadow: -1px 1px 0 #555555,
        1px 1px 0 #555555,
        1px -1px 0 #555555,
        -1px -1px 0 #555555;
    margin-right: 8px;
}

.single-location {
    font-size: 14px;
    margin-right: 10px;
}

.single-photo-owner {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.single-gallery-edit-buttons {
    display: flex;
    align-items: center;
}

.single-photo-comments-container {
    background-color: white;
    width: 600px;
    padding: 15px 24px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.small-profile-icon {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.new-comment-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.post-comment-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.comment-text {
    font-size: 18px;
    padding: 10px 16px 10px 20px;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 300px;
    height: 25px;
    margin-left: 8px;
}

.post-comment-form {
    width: 100%;
}

.comment-poster-prof {
    width: 10%;
}

.submit-cancel-comment {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
}

.comment-post-button {
    background-color: rgb(41, 134, 247);
    color: white;
    font-size: 16px;
    border-radius: 18px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    width: 150px;
    height: 30px;

}

.comment-post-button-disabled {
    background-color: lightgray;
    color: white;
    font-size: 16px;
    border-radius: 18px;
    border: none;
    font-weight: bold;
    width: 150px;
    height: 30px;
}

.cancel-comment-button {
    color: rgb(41, 134, 247);
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 30px;
    cursor: pointer;

}

.posted-comments-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.posted-comments-header {
    font-size: 18px;
    margin: 10px 0px;
}

.single-comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    overflow-wrap: normal;
    word-wrap: break-word;
}

.single-comment-text {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    margin-left: 8px;
}

.commenter-name-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.commenter-name {
    font-weight: bold;
}

.comment-date {
    font-size: 12px;
}

.edit-comment-text-box {
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow-wrap: normal;
    word-break: break-word;



}

.edit-delete-button-parent {
    display: flex;
    flex-direction: row;
    min-width: 90px;
}

.edit-comment-button,
.delete-comment-button {
    background-color: transparent;
    border: none;
    color: rgb(41, 134, 247);
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    margin: 0px 0px 0px 10px;
    padding: 0px;
}

.edit-comment-form-data {
    border-radius: 4px;
    color: lightgray;

}

.edit-comment-text {
    border: 1px solid lightgray;
    border-radius: 4px;
    height: 25px;
    padding: 10px 16px 10px 20px;
    font-size: 18px;
    width: 300px;
    margin-top: 5px;
}

.from-comment-to-profile {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.from-comment-to-profile:hover {
    color: rgb(41, 134, 247);
    transition: .5s;
}

.likes-modal-button-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
}

.photo-likes-modal {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 0px;

}

.photo-likes-modal:hover .likes-chevron, .photo-likes-modal:hover{
    color: rgb(41, 134, 247)
}

.likes-modal-people-person{
    margin-left: 5px;
}
.large-profile-icon{
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
}
.single-left-right-profile{
    display: flex;
    flex-direction: row;
}
.single-photo-edit-buttons{
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

}
.create-comment-profile-placeholder{
    display: flex;
    border: 1px solid lightgray;
    border-radius: 50%;
    min-width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.heart-liked-color {
    color: rgb(202, 8, 8);
}
.single-photo-like-button{
    background-color: transparent;
    border: none;
    font-size: 24px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}
.single-photo-like-button:hover {
    background-color: rgb(231, 231, 231);
    transition: .7s;
}
.add-gallery-modal-single-photo-page{
    position: relative;
    bottom: 0px;
    display: flex;
    color: #222222;
    width: 22px;
    height: 22px;
    border: 2px solid #222222;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    padding: 0px 1px 2px 1px;

}
.add-gallery-modal-single-photo-page:hover {
    background-color: rgb(231, 231, 231);
    transition: .7s;
}
.gallery-modal-button-single-photo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: transparent;
    border: none;
    border-radius: 50%;
    margin-right: 10px;
}
.gallery-modal-button-single-photo:hover {
    background-color: rgb(231, 231, 231);
    transition: .5s;
}

.photo-size {
    image-resolution: from-image 300dpi;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 20px 0px;
}
.mapped-photo-container {
    padding: 0px 64px;
}
.selected-subheader {
    color: #0870d1;
    text-decoration: none;
    padding-bottom: 5px;
    border-bottom: 2px solid #0870d1;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.not-selected-subheader {
    color: rgb(132, 132, 132);
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}
.selected-subheader:hover {
    color: #2986f7;
    border-bottom: 2px solid #2986f7;
}
.not-selected-subheader:hover {
    border-bottom: 2px solid #2986f7;
    padding-bottom: 5px;
    color: #2986f7;

}
.subheader-home {
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 63px;
    z-index: 1;
    height: 27px;
}
.all-photos {
    -webkit-column-count: 3;
            column-count: 3;
    grid-gap: 50px;
    gap: 50px;
    margin: 0px;
    padding: 0px;
}
.photo-card {
    max-width: 600px;
    cursor: pointer;
    position: relative;
    width: 100%;
}

.see-photos {
    font-weight: normal;
    color: rgb(109, 115, 120);
    line-height: 20px;
    font-size: 16px;
    padding: 0px 0px 12px;
    margin: 0px;
}
.photos-title {
    font-size: 36px;
    color: rgb(34, 34, 34);
    margin: 25px 0px 8px 0px;
}
.image-overlay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
    width: calc(100% - 25px);
    height: 50px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 0px 20px 0px 5px;
    text-align: center;
    z-index: 0;
    margin-bottom: 24px;
}
.image-overlay-2 {
    position: absolute;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
    width: calc(100% - 30px);
    height: 90px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 10px 20px 0px 10px;
    text-align: center;
    z-index: 0;
    margin-top: 20px;
}
.photo-card:hover .image-overlay , .photo-card:hover .image-overlay-2{
    opacity: 1;
    transition: 1.2s;
}

.overlay-2-text {
    display: flex;
    align-items: center;
    font-weight: lighter;
    align-self: left;
    margin: 0px;
    font-size: 16px;
}

.overlay-3-text {
    display: flex;
    font-weight: lighter;
    align-self: left;
    margin: 0px;
    font-size: 16px;
}
.add-gallery-modal {
    position: relative;
    bottom: 0px;
    display: flex;
    color: white;
    width: 22px;
    height: 22px;
    border: 2px solid white;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    padding: 0px 1px 2px 1px;
}
.gallery-modal-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: transparent;
    border: none;
    border-radius: 50%;
}
.overlay-bottom-text {
    position: absolute;
    bottom: 10px;
}
.overlay-right{
    position: absolute;
    right: 15px;
    bottom: 10px;
}
.gallery-modal-button:hover {

    background-color: rgba(229, 229, 229, 0.342);
    transition: .5s;
}
.all-photo-like-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 26px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.all-photo-like-button:hover, .all-photo-notlike-button:hover {

    background-color: rgba(229, 229, 229, 0.342);
    transition: .5s;
}
.all-photo-notlike-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: white;
    font-size: 26px;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.overlay-right-buttons{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1200px) {
    .all-photos {
        -webkit-column-count: 2;
                column-count: 2;
    }

}
@media screen and (min-width: 1600px) {
    .all-photos {
        -webkit-column-count: 4;
                column-count: 4;
    }
}
@media screen and (max-width: 800px) {
    .all-photos {
        -webkit-column-count: 1;
                column-count: 1;
    }
}


.upload-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.upload-container {

    font-size: 21px;
}
.upload-submit-buttons {
    display: flex;
    justify-content: center;
}
.upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.submit-upload {
    border: none;
    background-color: #0870d1;
    color: white;
    border-radius: 28px;
    cursor: pointer;
    width: 153px;
    height: 48px;
    font-size: 16px;
    margin: 20px;
}
input::file-selector-button {
    border: 2px solid #0870d1;
    color: #0870d1;
    background-color: white;
    border-radius: 28px;
    cursor: pointer;
    width: 130px;
    height: 40px;
    font-size: 16px;
}
input::file-selector-button:hover {
    border: none;
    color: white;
    background-color: #0870d1;
    transition: .7s;

}
input[type="file" i] {
    display: flex;
    flex-direction: column;
}
.upload-page-title {
    font-size: 21px;
    padding: 0px 64px;
}
.unlimited-uploads {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 100%;
    background-color: rgb(237, 246, 254);
}
.photo-requirements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;

}
.requirements-text {
    margin-top: 30px;
    background-color: rgb(238, 239, 242);
    padding: 15px;
}

.post-photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    max-height: 350px;
    border: 2px solid #0870d1;

}
.post-photo {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: cover;
    image-resolution: from-image 300dpi;

}

.post-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0px;
    margin: 0px 64px;

}
.upload-banner {
    margin: 0px 64px;
}
.post-whole-page-container {
    background-color: #f6f6f6dd;
}

.login-modal-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 400px;
}
.log-in-form {
    display: flex;
    flex-direction: column;
    align-items: center;

}
.login-header {
    display: flex;
    flex-direction: column;
    color: rgb(109, 115, 120);
    font-size: 14px;
}
.login-title{
    text-align: center;
    font-weight: normal;
    font-size: 24px;
}
.login-button {
    background-color: rgb(8, 112, 209);
    color: white;
    width: 100%;
    border: none;
    border-radius: 28px;
    padding: 12px 22px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.login-form-field {
    border: 1px solid rgb(215, 216, 219);
    height: 16px;
    padding: 12px 16px;
    font-size: 16px;
    color: rgb(34, 34, 34);
    border-radius: 4px;
    width: initial;
}
.login-modal-container {
    padding: 24px;
    border: 1px solid rgb(238, 239, 242);
}
.form-error {
    list-style: none;
}
.demo-login {
    background-color: white;
    border: 2px solid rgb(8, 112, 209);
    color: rgb(8, 112, 209);
    width: 100%;
    border-radius: 28px;
    padding: 12px 22px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
}
.login-button:hover {
    background-color: #2986f7;
    transition: .7s;
}
.demo-login:hover {
    background-color: rgb(237, 237, 237);
    transition: .7s;
}

.signup-form {
    display: flex;
    flex-direction: column;
}
.signup-modal-container {
    padding: 24px;
    overflow-y: auto;
}
.sign-up-title {
    text-align: center;
    font-size: 24px;
    color: black;
    font-weight: normal;
}
.discover-photos-text {
    font-size: 16px;
    color: rgba(176, 185, 194, 0.846);
}


.hidden {
  display: none;
}
.home-logo {
  text-decoration: none;
  font-size: 26px;
  color: black;
}
.nav-header-container {
  display:flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 10px 64px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 2;
}
.nav-right {
  display: flex;
  flex-direction: row;
}
.button-container {
  display: flex;
  flex-direction: row;
}
.log-in-modal {
  background-color: white;
  font-weight: bolder;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.log-in-modal:hover {
  color:  #1890ff;
  transition: .7s;
}
.sign-up-modal {
  border: 2px solid rgb(34, 34, 34);
  background-color: white;
  font-size: 16px;
  border-radius: 100px;
  padding: 0px 16px;
  font-weight: bolder;
  line-height: 16px;
  height: 32px;
  cursor: pointer;
}
.sign-up-modal:hover {
  transition: .7s;
  color:  #1890ff;
}
.nav-bar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-photo-button {
  border: 2px solid rgb(34, 34, 34);
  color: #222222;
  padding: 10px 22px;
  background: white;
  border-radius: 28px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.fa-arrow-up {
  margin-right: 10px;
  font-weight: light;
}
.upload-photo-button:hover {
  background-color: rgb(237, 237, 237);
  transition: .7s;
}
.profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  border-radius: 50%;
  border: none;
  text-align: center;
  height: 35px;
  width: 35px;
  cursor: pointer;

}
.profile-button-nav {
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-dropdown {
  position: absolute;
  background-color: white;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 11px 0px;
  padding: 10px 0px;
  height: auto;
  width: 150px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgb(195, 195, 195);
  z-index: 2;
}
.hidden {
  display: none;
}
.dropdown-profile-item {
  height: 30px;
  font-size: 14px;
  cursor: pointer;

}
.dropdown-profile-item:hover {
  background-color: #0870d1;
  transition: .7s;

}
.dropdown-button {
  background-color: transparent;
  text-align: left;
  padding-left: 15px;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  cursor: pointer;
  z-index: 3;
}
.dropdown-button:hover {
  color: white;
}
.nav-bar-search-wrapper{
  background-color: rgb(246, 246, 246);
  border-radius: 25px;
  padding: 0px 20px;
  height: 40px;
  width: 250px;
  margin: 0px 20px;
  text-indent: 48px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.nav-bar-search-text-field{
  border: none;
  background-color: rgb(246, 246, 246);
  height: 38px;
  width: 220px;
  text-indent: 10px;
  font-size: 16px;
}
.nav-bar-search-button{
  border: none;
  background-color: rgb(246, 246, 246);
  color: rgb(195, 195, 195);
  font-size: 16px;
  cursor: pointer;
}

.nav-bar-search-text-field:focus{
  outline: none;
}
.auto-search-link-item{
  text-decoration: none;
  color: black;
}
.nav-bar-search-autocomplete{
  position: absolute;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  top: 45px;
  z-index: 2;
  padding: 10px 0px;
  box-shadow: 0px 0px 5px lightgray;

}
.auto-search-item{
  list-style-type: none;
}
.auto-search-link-item{
  text-decoration: none;
  color: black;
  padding: 10px 20px;
}
.auto-search-link-item:hover{
  background-color: rgb(231, 231, 231);
}
.fa-paper-plane {
  font-size: 24px;
  color:rgb(34, 34, 34);
  margin-right: 20px;
  cursor: pointer;
}

textarea {
    resize: none;
    overflow-y: auto;
}
.delete-photo-button {
    display: inline-flex;
    border: 2px solid rgb(219, 56, 56);
    border-radius: 28px;
    background-color: white;
    color: rgb(219, 56, 56);
    margin: 15px 0px 0px 0px;
    cursor: pointer;
    font-size: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-height: 48px;
    padding: 12px 22px;
    font-weight: bold;
}
.photo-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

}
.create-product-submit-button {
    background-color: #0870d1;
    color: white;
    border: none;
    border-radius: 28px;
    min-width: 140px;
    height: 48px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    margin: 15px 0px;
}
.create-product-submit-button:hover{
    background-color: #2986f7;
    transition: .7s;
}
.post-form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 20px;
    height: 750px;
    width: 360px;
    overflow-y: auto;
    border: 1px solid #e4e5e8;
    background-color: #fff;
}
.edit-form-title{
    font-size: 16px;
    font-weight: bold;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background-color: #fff;
    width: 100%;
    height: 100px;
    text-align: center;
}
.form-is-required{
    align-self: flex-end;
    font-size: 14px;
    color: #6d7378;
}
.edit-input {
    margin: 0px;
    font-size: 14px;
    color: rgb(109, 115, 120);
    margin-bottom: 4px;
}
.select-field{
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px solid rgb(215, 216, 219);
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.5;
}
.input-box {
    width: 90%;
    height: 20px;
    border-radius: 4px;
    border: 1px solid rgb(215, 216, 219);
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.5;
}
.textarea-box{
    width: 90%;
    height: 84px;
    border-radius: 4px;
    border: 1px solid rgb(215, 216, 219);
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.5;
}
.input-box::-webkit-input-placeholder, .textarea-box::-webkit-input-placeholder   {
    color: rgb(109, 115, 120);
}
.input-box::placeholder, .textarea-box::placeholder, .select-field   {
    color: rgb(109, 115, 120);
}
.form-error {
    width: 100%;
    color: rgb(219, 56, 56);
    min-height: 24px;
    font-size: 14px;
}
.photo-delete-buttons-edit-page{
    display: flex;
    flex-direction: row;
}

.gallery-card {
    width: 100%;
    height: 290px;
    cursor: pointer;
    box-shadow: 1px 2px 5px lightgray;
}

.all-galleries {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    gap: 15px;
    padding: 5px 64px 20px 64px;
}

.all-gallery-image {
    width: 100%;
    height: 70%;
    object-fit: cover;
    margin: 0px;
}

.all-gallery-header {
    padding: 0px 64px;
}

.all-gallery-title {
    font-size: 36px;
    margin: 25px 0px 8px 0px;
    color: #222222;
}

.all-gallery-subheader {
    font-weight: normal;
    color: rgb(109, 115, 120);
    line-height: 20px;
    font-size: 16px;
    padding: 0px 0px 12px;
    margin: 0px;
}

.all-gallery-prof {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 7px;
}

.all-gallery-curated {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 16px;
}

.all-gallery-owner {

    margin: 0px;
    font-size: 16px;
}

.all-gallery-lower {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.all-gallery-to-profile {
    text-decoration: none;
    color: #0870D1;
    cursor: pointer;
    font-size: 16px;
}

.to-profile-button {
    background-color: transparent;
    border: none;
}

.all-galleries-background {
    background-color: rgb(247, 248, 250);
    padding-top: 3px;
    margin: 10px 0px;
}

@media screen and (max-width: 1200px) {
    .all-galleries {
        grid-template-columns: repeat(3, 1fr);
    }

}
@media screen and (min-width: 1600px) {
    .all-galleries {
        grid-template-columns: repeat(5, 1fr);
    }
}
@media screen and (max-width: 800px) {
    .all-galleries {
        grid-template-columns: repeat(2, 1fr);
    }

}

.photos-mapped-modal{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px;

}
.my-modal-photo {
    width: 200px;
    height: 200px;
    image-resolution: from-image 300dpi;
    object-fit: cover;
}
.my-modal-photo-container {
    width: 90%;
}
.photos-modal-container {
    min-width: 700px;
    max-height: 500px; 
    overflow-y: auto;
    padding: 16px;
    text-align: center;
    font-size: 14px;
}
.modal-photo-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.my-modal-photo-button {
    background-color: transparent;
    border: 2px solid transparent;
    padding: 2px 2px .5px 2px;
}
.border-click-on {
    border: 2px solid blue;
}
.add-photos-gallery-button {
    background-color: rgb(8, 112, 209);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 150px;
    cursor: pointer;
    text-align: center;
    margin: 15px;
}
.select-photos-modal-title {
    font-size: 20px;
}

.single-gallery-cover {
    width: 100%;
    max-height: 370px;

}
.gallery-single-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edit-gallery-buttons {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
    margin-right: 40px;

}
.single-gallery-title {
    font-size: 21px;
    line-height: 24px;
}
.single-gallery-prof-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.single-gallery-photo-container {
    background-color: rgb(247, 248, 250);
    -webkit-column-count: 3;
            column-count: 3;
    grid-gap: 8px;
    gap: 8px;
    margin: 0px;
    padding: 60px 5px;
    width: 100%;
}
.image-size {
    image-resolution: from-image 300dpi;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 2px 0px;
}
.edit-gallery-button {
    background-color: transparent;
    border: none;
    cursor: pointer;

}
.gallery-placeholder{
    background-color: rgb(40, 40, 40);
    width: 100%;
    height: 370px;
}
.add-photos-gallery-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 700px;
    height: 700px;
    justify-content: center;
}
.fa-arrow-left-long {
    color: black;
}
.add-to-gallery-modal {
    padding: 0px;
    margin-right: 8px;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
.image-overlay-3 {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
    width: calc(100% - 25px);
    height: 90px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 10px 20px 0px 5px;
    text-align: center;
    z-index: 1;
    margin-top: 1.5px;
}
.photo-card:hover .image-overlay-3 , .photo-card:hover .image-overlay-4{
    opacity: 1;
    transition: 1.2s;
}
.image-overlay-4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
    width: calc(100% - 25px);
    height: 50px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 0px 20px 0px 5px;
    text-align: center;
    z-index: 1;
    margin-bottom: 5px;
}
.delete-from-gallery-button {
    height: 35px;
    width: 35px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 50%;
}
.fa-trash-can {
    font-size: 22px;
}
.delete-from-gallery-button:hover {
    transition: .7s;
    background-color: rgba(229, 229, 229, 0.342);
}
.add-to-gallery-button {
    background-color: white;
    color: rgb(8, 112, 209);
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: 2px solid rgb(8, 112, 209);
    width: 270px;
    cursor: pointer;
    text-align: center;
}
.add-gallery-photo-header {
    margin: 10px 0px 0px 0px;
    font-size: 20px;
}

.follower-modal-container{
    width: 300px;
}
.follower-modal-header{
    text-align: center;
    font-size: 20px;
}
.follower-modal-name-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 10px;
}

.prof-cover-photo {
    width: 100%;
    object-fit: cover;
    height: 370px;
}

.prof-photo {
    display: flex;
    align-items: center;
    width: 100px;
    height: 100px;
    justify-content: center;
    top: 390px;
    border-radius: 50%;
    border: 1px solid gray;
    background-color: white;
    padding: 2px;
    object-fit: cover;
}

.profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.profile-edit-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 16px;
    width: 100%;
    height: 40px;

}

.user-profile-name {
    font-size: 24px;
    font-weight: bold;

}

.user-photos-container {
    background-color: rgb(247, 248, 250);
    -webkit-column-count: 3;
            column-count: 3;
    grid-gap: 8px;
    gap: 8px;
    padding: 7px 0px;
    width: 100%;

}
.no-user-photos-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(247, 248, 250);
    margin: 5px 0px 0px 0px;
}
.edit-profile-button-page {
    align-self: flex-end;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 20px 25px 0px 0px;
}

.prof-photo-container {
    width: 100%;
    display: flex;
    position: absolute;
    top: 390px;
    justify-content: center;
    /* max-width: 1270px; */

}

.profile-container {
    width: 100%;

}

.prof-images-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fa-user-large {
    font-size: 40px;
    border-radius: 50%;
    color: rgb(191, 191, 191);
}

.profile-link-headers {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}

.work-in-progress-photos {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

}

.fa-images {
    font-size: 30px;
    color: rgb(47, 47, 47);
}

.work-in-progress-title {
    font-size: 21px;
    color: rgb(47, 47, 47);
}

.profile-no-photos-yet {
    font-size: 16px;
    color: rgb(47, 47, 47);
    margin: 15px;
}
.user-follower-following-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    margin-top: 10px;

}
.user-followers-count{
    margin-right: 10px;
}
.profile-follow-user-button {
    background-color: rgb(8, 112, 209);
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 27px;
    border-radius: 28px;
    border: none;
    width: 125px;
    cursor: pointer;
    margin-top: 10px;
}
.profile-follow-user-button:hover {
    background-color: #2986f7;
    transition: .7s;
}
.profile-unfollow-user-button{
    position: relative;
    background-color: white;
    text-align: center;
    color: rgb(130, 130, 130);
    font-size: 16px;
    font-weight: bold;
    padding: 6px 25px;
    border: 2px solid rgb(8, 112, 209);
    width: 125px;
    border-radius: 28px;
    cursor: pointer;
    margin-top: 10px;
    content: "Following";
}
.profile-unfollow-user-button:hover span{
    visibility: hidden;
}
.profile-unfollow-user-button:hover::before{
    content: "Unfollow";
    position: absolute;

}
.profile-unfollow-user-button:hover{
    border: 2px solid red;
    color: red;
}


@media screen and (max-width: 1200px) {
    .user-photos-container {
        -webkit-column-count: 2;
                column-count: 2;
    }
}

@media screen and (min-width: 1600px) {
    .user-photos-container {
        -webkit-column-count: 4;
                column-count: 4;
    }
}

@media screen and (max-width: 800px) {
    .user-photos-container {
        -webkit-column-count: 1;
                column-count: 1;
    }
}

.create-gallery-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.fa-square-plus {
    font-size: 40px;
}
.create-gallery-curate {
    font-size: 17px;
    text-align: center;
    color: #222222;
}
.create-button-gallery {
    text-decoration: none;
    background-color: rgb(8, 112, 209);
    border-radius: 28px;
    color: white;
    cursor: pointer;
    padding: 12px 22px;
    font-weight: 600;
}
.create-button-gallery:hover{
    background-color: #2986f7;
    transition: .7s;
}
.work-in-progress-galleries {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

}

.edit-gallery-submit-button {
    background-color: rgb(8, 112, 209);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 165px;
    cursor: pointer;
}
.edit-gallery-submit-button:hover {
    background-color: #2986f7;
    transition: .7s;
}
.delete-gallery-button {
    color:  rgb(219, 56, 56);
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: bold;
    width: 170px;
    cursor: pointer;
}
.edit-gallery-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}

.create-gallery-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eaeaea;
    height: auto;
    min-height: calc((100vh - 72px) - 56px)
}

.create-gallery-form-title {
    text-align: center;
    width: 100%;
    background-color: white;
    margin: 0px;
}
.create-gallery-form-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    /* box-shadow: 0px 5px 4px gray; */
}
.fa-arrow-left-long {
    font-size: 22px;
}
.gallery-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    padding: 24px;
    background-color: white;
    width: 560px;
    font-size: 14px;
    margin-top: 60px;
}
.visible-container {
    display: flex;
    flex-direction: row;
}
.gallery-form-input {
    border: 1px solid rgb(215, 216, 219);
    height: 20px;
    padding: 12px 16px;
    border-radius: 4px;
    width: 500px;
}
.gallery-textarea-input {
    border: 1px solid rgb(215, 216, 219);
    height: 100px;
    padding: 12px 16px;
    border-radius: 4px;
    width: 500px;
    resize: vertical;
}
.gallery-radio-input {
    display: flex;
    align-self: left;
    width: 25px;
    margin-right: 5px;
}
.visible-container {
    width: 525px;
}
.button-text {
    padding: 0px;
    margin: 5px;
}
.gallery-form-label{
    margin: 5px 0px;
    color: rgb(109, 115, 120);
}
.fa-lock {
    color: white;
    text-shadow: -1px 1px 0 #000,
    1px 1px 0 #000,
   1px -1px 0 #000,
  -1px -1px 0 #000;
  font-size: 14px;
}
.fa-eye {
    font-size: 14px;
}
.create-gallery-submit-button {
    background-color: rgb(8, 112, 209);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 120px;
    cursor: pointer;
}
.cancel-gallery-button {
    color: rgb(8, 112, 209);
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 120px;
    text-decoration: none;
    cursor: pointer;
}
.cancel-gallery-button:hover {
    color: #2986f7;
    transition: .7s;
}
.create-gallery-form-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.edit-profile-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.edit-profile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 120%;
    box-shadow: 0px 2px 5px rgb(228, 228, 228);

}
.edit-profile-title {
    font-size: 16px;

}
.edit-profile-back {
    position: absolute;
    top: 80px;
    left: 20px;
}
.edit-profile-images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid rgb(238, 239, 242);
    max-width: 560px;
    width: 100%;
    margin-top: 25px;
}
.edit-profile-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 560px;
    width: 100%;

}
.edit-profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-row-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.edit-profile-images {
    width: 100%;
    position: relative;
    height: 220px;
}
.edit-profile-cover-photo {
    width: 100%;
    height: 180px;
    object-fit: cover;
}
.edit-prof-photo-container {
    width: 100%;
    display: flex;
    position: relative;
    top: -55px;
    justify-content: center;
}
.edit-prof-photo {
    display: flex;
    align-items: center;
    width: 100px;
    height: 100px;
    right: 50%;
    justify-content: center;
    top: 390px;
    border-radius: 50%;
    background-color: white;
    padding: 2px;
    object-fit: cover;
}
.upload-cover-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}
.upload-profile-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.edit-profile-cover-upload-submit-buttons {
    position: absolute;
    top: 65px;
    left: 165px;
}
.edit-profile-photo-upload-submit-buttons {
    position: absolute;
    top: 180px;
    left: 295px;

}
.change-cover-button {
    background-color: white;
    min-width: 130px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 28px;
    height: 50px;
    padding: 12px 18px;
    border: 2px solid rgb(8, 112, 209);
    color: rgb(8, 112, 209);
    font-weight: bold;
    text-align: center;
}
.change-cover-button:hover, .change-profile-button:hover {
    transition: .7s;
    background-color: rgb(228, 228, 228);
}
.fa-camera {
    font-size: 20px;
    color:rgb(8, 112, 209);
}
.change-profile-button{
    border-radius: 50%;
    background-color: white;
    box-shadow: 2px 2px 2px lightgray;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
}

.submit-cover-photo {
    background-color: white;
    min-width: 90px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 28px;
    height: 40px;
    padding: 8px 16px;
    margin-left: 5px;
    border: 2px solid rgb(8, 112, 209);
    color: rgb(8, 112, 209);
    font-weight: bold;
    text-align: center;
}
.submit-cover-photo:hover {
    transition: .7s;
    background-color: rgb(228, 228, 228);
}
.submit-profile-photo{
    background-color: white;
    min-width: 60px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 28px;
    height: 35px;
    padding: 6px 12px;
    margin-left: 5px;
    border: 2px solid rgb(8, 112, 209);
    color: rgb(8, 112, 209);
    font-weight: bold;
    text-align: center;
}
.loading-text-profile {
    color: rgb(8, 112, 209);
    background-color: white;
    border-radius: 28px;
    margin-left: 5px;
    font-size: 12px;

}
.profile-form-label {
    margin: 5px 0px;
    font-size: 14px;
    color: rgb(109, 115, 120);
}
.profile-form-input {
    height: 20px;
    width: 190px;
    border-radius: 4px;
    border: 1px solid rgba(134, 138, 141, 0.43);
    font-size: 16px;
    padding: 12px 16px;
}
.profile-form-padding {
    padding: 10px;

}
.profile-textarea-input{
    width: 435px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid rgba(134, 138, 141, 0.43);
    font-size: 16px;
    padding: 12px 16px;
    overflow-y: auto;
}
.submit-edit-profile {
    background-color: rgb(8, 112, 209);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 165px;
    cursor: pointer;
}
.submit-edit-profile:hover{
    background-color: #2986f7;
    transition: .7s;
}
.cancel-edit-profile:hover{
    color: #2986f7;
    transition: .7s;
}
.cancel-edit-profile {
    background-color: white;
    color: rgb(8, 112, 209);
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 48px;
    border-radius: 28px;
    border: none;
    width: 100px;
    cursor: pointer;
    text-align: center;
}
.edit-profile-cancel-submit {
    display: flex;
    flex-direction: row;
    width: 470px;
    justify-content: flex-end;
    margin-bottom: 25px;
}
.errors-profile-edit{
    height: 20px;
    font-size: 12px;
    color: red;
    margin-top: 2px;
}

.splash-photo-container{

    width: 100%;

}
.splash-page-carousel{
    position: relative;
    opacity: 1;
    height: 550px;
    width: 100%;
    object-fit: cover;
    /* transition: opacity .7s ease-in-out; */
    z-index: 0;
    object-position: 60% 80%;



}
.carousel {
    height: 550px;
}
.splash-page-container {
    width: 100%;
}
.text-over-photo-splash {
    position: absolute;
    top: 200px;
    left: 50px;
    color: white;
    width: 40%;
    z-index: 2;

}
.detail-text-splash{
    line-height: 1.5;
    font-size: 18px;;
}
.linear-gradient-splash {
    height: 550px;
    width: 100%;
    background: linear-gradient(0.25turn, rgba(23, 22, 21, 0.812),rgba(0, 0, 0, 0.025), rgba(0, 0, 0, 0.025));
    z-index: 1;
    position: absolute;
    top: 65px;
}
.sign-up-modal-splash{
    border-radius: 24px;
    border: none;
    background-color: white;
    padding: 12px 20px;
    width: 200px;
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
}
.sign-up-modal-splash:hover{
    background-color: rgb(228, 228, 228);
    transition: .7s;
}
.editors-picks-photo-container{
    -webkit-column-count: 4;
            column-count: 4;
    grid-gap: 8px;
    gap: 8px;
}
.editors-choice-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.liked-photos-header{
    font-size: 24px;
    padding: 24px 64px;
    margin: 0px;
}
.no-liked-prompt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
}
.likes-discover-photos-button{
    background-color: rgb(8, 112, 209);
    text-decoration: none;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 22px;
    border-radius: 28px;
    border: none;
    width: 165px;
    cursor: pointer;
}
.likes-discover-photos-button:hover {
    background-color: #2986f7;
    transition: .7s;
}

.main-footer-container {
    /* border: 3px green solid; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(34,34,34,.15);
    color: #222;
    font-size: 14px;
    line-height: 1.4;
    padding: 45px 100px;
}

.tech-and-about-container {
    /* border: pink 3px solid; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    margin-bottom: 30px;
}

.first-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.second-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.third-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.fourth-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.first-column :hover {
    text-decoration: underline;
}

.second-column :hover {
    text-decoration: underline;
}

.third-column :hover {
    text-decoration: underline;
}
.fourth-column :hover {
    text-decoration: underline;
}

.all-search-container{
    padding: 0px 64px;
}
.search-subheader{
    font-weight: normal;
    font-size: 16px;
}
.all-search{
    padding: 0px;
}

.direct-message{
    font-size: 16px;
    padding: 10px;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 33rem;
    margin: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.direct-message.follower{
    background-color: rgb(247, 248, 250);
    color: rgb(34, 34, 34);
    border-radius: 1rem 1rem 1rem 0px;
    float: left;
}
.direct-message.user{
    background-color: rgb(8, 112, 209);
    color: white;
    border-radius: 1rem 1rem 0px;
    text-align: end;
    float: right;

}

/* .direct-message-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
} */
.direct-message-typing-box-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0px;
    border-top: 1px solid lightgray;
    height: 40px;
    background-color: white;

}
.direct-message-send{
    background-color:rgb(8, 112, 209);
    color: white;
    border: none;
    border-radius: 28px;
    padding: 5px 16px;
    font-weight: bold;
    font-size: 16px;
    margin-right: 5px;
}
.direct-message-typing-box{
    width: 80%;
    border: none;
    height: 32px;
    font-size: 16px;
    margin-left: 5px;
}
.direct-message-typing-box:focus{
    outline: none;
}
.direct-messages-parent{
    height: 406px;
    overflow-y: scroll;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
}
.direct-message-option{
    border: none;
    font-size: 12px;
    background-color: white;
    position: absolute;
    bottom: -15px;
    right: 10px;
    cursor: pointer;
    color: gray;
    padding: 5px;
}
.direct-message-option:hover{
    color:rgb(8, 112, 209);
}
.direct-message-single-parent{
    position: relative;
    margin-bottom: 8px;
}
.direct-message-time{
    text-align: center;
    font-size: 12px;
}
.under-construction-img {
    width: 100%;

}


.messages-background-container{
    background-color: rgb(247, 248, 250);
    width: 100%;
    display: flex;
    justify-content: center;
}
.all-messages-container {

    display: flex;
    flex-direction: row;
    height: 500px;
    width: 50%;
    min-width: 400px;
    background-color: white;
}
.messaging-current-following-container{
    width: 40%;
    border-right: 1px solid lightgray;
}
.all-messages-title{
    margin: 0px;
    font-size: 24px;
    padding: 12px 0px 12px 10px;
    border-bottom: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.all-messages-user-name{
    font-weight: bold;
    margin-left: 15px;
}
.all-messages-user{
    cursor: pointer;
}
.all-messages-user:hover{
    background-color: lightgray;
}
.medium-profile-icon {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.all-messages-single-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 5px;
}
.all-messages-users-container{
    overflow-y: auto;
    max-height: 1000px;
}
.all-messages-selected-follower{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11.5px;
    border-bottom: 1px solid lightgray;
}
.all-messages-single-conversation{
    width: 60%;
    position: relative;
}
/* .all-messages-following-subheader{
    color: rgb(191, 191, 191);
    font-weight: bold;
    font-size: 12px;
    margin: 5px 5px;
} */

.not-found-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.not-found{
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-found2{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: rgb(110, 110, 110);
}

.image-not-found{
    display: flex;
    justify-content: center;
    align-items: center;

}

/* TODO Add site wide styles */

body{
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

input,
textarea,
button {
  font-family: inherit;
}

