.prof-cover-photo {
    width: 100%;
    object-fit: cover;
    height: 370px;
}

.prof-photo {
    display: flex;
    align-items: center;
    width: 100px;
    height: 100px;
    justify-content: center;
    top: 390px;
    border-radius: 50%;
    border: 1px solid gray;
    background-color: white;
    padding: 2px;
    object-fit: cover;
}

.profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.profile-edit-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 16px;
    width: 100%;
    height: 40px;

}

.user-profile-name {
    font-size: 24px;
    font-weight: bold;

}

.user-photos-container {
    background-color: rgb(247, 248, 250);
    column-count: 3;
    gap: 8px;
    padding: 7px 0px;
    width: 100%;

}
.no-user-photos-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(247, 248, 250);
    margin: 5px 0px 0px 0px;
}
.edit-profile-button-page {
    align-self: flex-end;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 20px 25px 0px 0px;
}

.prof-photo-container {
    width: 100%;
    display: flex;
    position: absolute;
    top: 390px;
    justify-content: center;
    /* max-width: 1270px; */

}

.profile-container {
    width: 100%;

}

.prof-images-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fa-user-large {
    font-size: 40px;
    border-radius: 50%;
    color: rgb(191, 191, 191);
}

.profile-link-headers {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}

.work-in-progress-photos {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

}

.fa-images {
    font-size: 30px;
    color: rgb(47, 47, 47);
}

.work-in-progress-title {
    font-size: 21px;
    color: rgb(47, 47, 47);
}

.profile-no-photos-yet {
    font-size: 16px;
    color: rgb(47, 47, 47);
    margin: 15px;
}
.user-follower-following-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    margin-top: 10px;

}
.user-followers-count{
    margin-right: 10px;
}
.profile-follow-user-button {
    background-color: rgb(8, 112, 209);
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 27px;
    border-radius: 28px;
    border: none;
    width: 125px;
    cursor: pointer;
    margin-top: 10px;
}
.profile-follow-user-button:hover {
    background-color: #2986f7;
    transition: .7s;
}
.profile-unfollow-user-button{
    position: relative;
    background-color: white;
    text-align: center;
    color: rgb(130, 130, 130);
    font-size: 16px;
    font-weight: bold;
    padding: 6px 25px;
    border: 2px solid rgb(8, 112, 209);
    width: 125px;
    border-radius: 28px;
    cursor: pointer;
    margin-top: 10px;
    content: "Following";
}
.profile-unfollow-user-button:hover span{
    visibility: hidden;
}
.profile-unfollow-user-button:hover::before{
    content: "Unfollow";
    position: absolute;

}
.profile-unfollow-user-button:hover{
    border: 2px solid red;
    color: red;
}


@media screen and (max-width: 1200px) {
    .user-photos-container {
        column-count: 2;
    }
}

@media screen and (min-width: 1600px) {
    .user-photos-container {
        column-count: 4;
    }
}

@media screen and (max-width: 800px) {
    .user-photos-container {
        column-count: 1;
    }
}
