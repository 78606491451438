/* TODO Add site wide styles */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans');

body{
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

input,
textarea,
button {
  font-family: inherit;
}
