.liked-photos-header{
    font-size: 24px;
    padding: 24px 64px;
    margin: 0px;
}
.no-liked-prompt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
}
.likes-discover-photos-button{
    background-color: rgb(8, 112, 209);
    text-decoration: none;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 22px;
    height: 22px;
    border-radius: 28px;
    border: none;
    width: 165px;
    cursor: pointer;
}
.likes-discover-photos-button:hover {
    background-color: #2986f7;
    transition: .7s;
}
