.all-search-container{
    padding: 0px 64px;
}
.search-subheader{
    font-weight: normal;
    font-size: 16px;
}
.all-search{
    padding: 0px;
}
