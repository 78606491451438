
.hidden {
  display: none;
}
.home-logo {
  text-decoration: none;
  font-size: 26px;
  color: black;
}
.nav-header-container {
  display:flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 10px 64px;
  position: sticky;
  top: 0px;
  z-index: 2;
}
.nav-right {
  display: flex;
  flex-direction: row;
}
.button-container {
  display: flex;
  flex-direction: row;
}
.log-in-modal {
  background-color: white;
  font-weight: bolder;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.log-in-modal:hover {
  color:  #1890ff;
  transition: .7s;
}
.sign-up-modal {
  border: 2px solid rgb(34, 34, 34);
  background-color: white;
  font-size: 16px;
  border-radius: 100px;
  padding: 0px 16px;
  font-weight: bolder;
  line-height: 16px;
  height: 32px;
  cursor: pointer;
}
.sign-up-modal:hover {
  transition: .7s;
  color:  #1890ff;
}
.nav-bar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-photo-button {
  border: 2px solid rgb(34, 34, 34);
  color: #222222;
  padding: 10px 22px;
  background: white;
  border-radius: 28px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.fa-arrow-up {
  margin-right: 10px;
  font-weight: light;
}
.upload-photo-button:hover {
  background-color: rgb(237, 237, 237);
  transition: .7s;
}
.profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  border-radius: 50%;
  border: none;
  text-align: center;
  height: 35px;
  width: 35px;
  cursor: pointer;

}
.profile-button-nav {
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-dropdown {
  position: absolute;
  background-color: white;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 11px 0px;
  padding: 10px 0px;
  height: auto;
  width: 150px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgb(195, 195, 195);
  z-index: 2;
}
.hidden {
  display: none;
}
.dropdown-profile-item {
  height: 30px;
  font-size: 14px;
  cursor: pointer;

}
.dropdown-profile-item:hover {
  background-color: #0870d1;
  transition: .7s;

}
.dropdown-button {
  background-color: transparent;
  text-align: left;
  padding-left: 15px;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  cursor: pointer;
  z-index: 3;
}
.dropdown-button:hover {
  color: white;
}
.nav-bar-search-wrapper{
  background-color: rgb(246, 246, 246);
  border-radius: 25px;
  padding: 0px 20px;
  height: 40px;
  width: 250px;
  margin: 0px 20px;
  text-indent: 48px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.nav-bar-search-text-field{
  border: none;
  background-color: rgb(246, 246, 246);
  height: 38px;
  width: 220px;
  text-indent: 10px;
  font-size: 16px;
}
.nav-bar-search-button{
  border: none;
  background-color: rgb(246, 246, 246);
  color: rgb(195, 195, 195);
  font-size: 16px;
  cursor: pointer;
}

.nav-bar-search-text-field:focus{
  outline: none;
}
.auto-search-link-item{
  text-decoration: none;
  color: black;
}
.nav-bar-search-autocomplete{
  position: absolute;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  top: 45px;
  z-index: 2;
  padding: 10px 0px;
  box-shadow: 0px 0px 5px lightgray;

}
.auto-search-item{
  list-style-type: none;
}
.auto-search-link-item{
  text-decoration: none;
  color: black;
  padding: 10px 20px;
}
.auto-search-link-item:hover{
  background-color: rgb(231, 231, 231);
}
.fa-paper-plane {
  font-size: 24px;
  color:rgb(34, 34, 34);
  margin-right: 20px;
  cursor: pointer;
}
