
.upload-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.upload-container {

    font-size: 21px;
}
.upload-submit-buttons {
    display: flex;
    justify-content: center;
}
.upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.submit-upload {
    border: none;
    background-color: #0870d1;
    color: white;
    border-radius: 28px;
    cursor: pointer;
    width: 153px;
    height: 48px;
    font-size: 16px;
    margin: 20px;
}
input::file-selector-button {
    border: 2px solid #0870d1;
    color: #0870d1;
    background-color: white;
    border-radius: 28px;
    cursor: pointer;
    width: 130px;
    height: 40px;
    font-size: 16px;
}
input::file-selector-button:hover {
    border: none;
    color: white;
    background-color: #0870d1;
    transition: .7s;

}
input[type="file" i] {
    display: flex;
    flex-direction: column;
}
.upload-page-title {
    font-size: 21px;
    padding: 0px 64px;
}
.unlimited-uploads {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 100%;
    background-color: rgb(237, 246, 254);
}
.photo-requirements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;

}
.requirements-text {
    margin-top: 30px;
    background-color: rgb(238, 239, 242);
    padding: 15px;
}
